import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDossier } from "../../../hooks/useDossier";
import { Dossier } from "../../../models/Dossier";
import { GeoJSON, Pane } from 'react-leaflet';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, Badge, Accordion, Spinner, Modal } from "react-bootstrap";
import { useMapInfo } from "../../../hooks/useMapInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faDownload, faEye, faEyeSlash, faHouse, faLocationCrosshairs, faSquare, faSquareFull } from "@fortawesome/free-solid-svg-icons";
import { useCarto } from "../../../hooks/useCarto";
import { useApi } from "../../../hooks/useApi";
import { Feature, FeatureCollection } from "geojson";
import { latLng, Layer } from "leaflet";
import L from "leaflet";
import { useUser } from "../../../hooks/useUser";
import { get } from "http";
import { useTransaction } from "../../../hooks/useTransaction";
import Swal from "sweetalert2";

//Params
interface TransactionModalParams {
    cadastre: Feature;
    nbCredits: number;
    open: boolean;
    setOpen: any;
}

export const TransactionModal = ({ cadastre, nbCredits, open, setOpen }: TransactionModalParams) => {
    /**
     * Hooks
     */
    const { profile } = useUser();
    const { commanderCadastre } = useTransaction();
    const { carto, setSelectedParcelle } = useCarto();

    /**
     * States
     */
    const [soldeCredits, setSoldeCredits] = useState(0);
    const [loadingTransaction, setLoadingTransaction] = useState(false);
    

    /**
     * Constants
     */
    
    
    /**
     * useEffect
     */
    useEffect(() => {
        if(profile.contact) {
            setSoldeCredits(profile.contact.tier.credits??0);
        }
        else {
            setSoldeCredits(0);
        }
    }, [profile]);

    /**
     * Handlers
     */
    const handleCreateTransaction = () => {
        setSelectedParcelle(null);
        setLoadingTransaction(true);
        commanderCadastre(cadastre.properties.code)
            .then((cadastre) => {
                Swal.fire({
                    title: 'Transaction enregistrée',
                    text: 'La transaction a bien été enregistrée. Vous pouvez maintenant visualiser les informations du cadastre.',
                    icon: 'success'
                });
                setSelectedParcelle(cadastre);
                setLoadingTransaction(false);
                setOpen(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de la transaction. Veuillez réessayer ultérieurement.',
                    icon: 'error'
                });
                setLoadingTransaction(false);
                setOpen(false);
            });
    }

    /**
     * Functions
     */
    

    /**
     * Render
     */
    if(soldeCredits == null) return null;

    return (
        <>
            <Modal show={open} onHide={() => setOpen(false)}>
                <Modal.Header>
                    <Modal.Title>Commande</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Pour pouvoir visualiser les informations de cette parcelle, merci de réaliser une transaction de <strong>{nbCredits} crédits</strong>.</p>
                    <p>Une fois la transaction enregistrée, vous aurez la possibilité de visualiser l'ensemble de nos données concernant cette parcelle ainsi que toutes les parcelles situées dans un rayon de 200m.</p>
                    <p>
                        Parcelle concernée:
                        <ul>
                            <li><strong>Commune</strong> {cadastre?.properties?.commune.name}</li>
                            <li><strong>Réf.</strong> {cadastre?.properties?.prefixe} / {cadastre?.properties?.section} / {cadastre?.properties?.numero}</li>
                            <li><strong>Surface</strong> {cadastre?.properties?.contenance}m²</li>
                        </ul>
                    </p>
                    {soldeCredits < nbCredits && <p className="text-danger">Vous n'avez pas assez de crédits pour réaliser cette transaction. Rapprochez-vous de votre référent Liaisons Habitat pour obtenir plus de crédits.</p>}
                    {soldeCredits >= nbCredits && <p className="text-success">Votre solde de crédits est actuellement de {soldeCredits}.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpen(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleCreateTransaction} disabled={soldeCredits < nbCredits || loadingTransaction}>
                        <>
                            {loadingTransaction ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <>Acheter pour {nbCredits} crédits</>
                            )}
                        </>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
