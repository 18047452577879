import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircle, faCircleDot, faDollar, faEllipsis, faFile, faFolder, faHome, faInfoCircle, faLayerGroup, faLocationCrosshairs, faTriangleExclamation, faUser } from '@fortawesome/free-solid-svg-icons';
import { Container, Nav, Badge, Button, Spinner } from 'react-bootstrap';
import logoMini from '../../../assets/logo_mini.png';
import logo from '../../../assets/logo.png';
import { useUser } from '../../../hooks/useUser';
import { useMapInfo } from '../../../hooks/useMapInfo';
import { useCarto } from '../../../hooks/useCarto';
import { TransactionModal } from './TransactionModal';

const menuItems = [
  { id: 'mutations', icon: faDollar, label: 'Mutations' },
  { id: 'documentsUrbanisme', icon: faFile, label: 'Documents d\'urbanisme' },
  { id: 'zonesUrbaine', icon: faLayerGroup, label: 'Zonage urbain' },
  { id: 'informations', icon: faInfoCircle, label: 'Informations' },
  { id: 'servitudes', icon: faTriangleExclamation, label: 'Servitudes' },
];

interface SidebarParams {
    onSelectItem?: any;
    isLibre?: boolean;
}

export default function Sidebar({ onSelectItem, isLibre }: SidebarParams) {
  /**
   * Hooks
   */
  const { mapInfo, centerMap } = useMapInfo();
  const location = useLocation();
  const { profile, getProfile } = useUser();
  const { carto, setSelectedCard } = useCarto();

  /**
   * States
   */
  const [ isExpanded, setIsExpanded ] = useState(false);
  const [ fixExpanded, setFixExpanded ] = useState(true);
  const [ selectedFeaturesCount, setSelectedFeaturesCount ] = useState([]);
  const [ soldeCredits, setSoldeCredits ] = useState(0);
  const [ showTransactionModal, setShowTransactionModal ] = useState(false);
    
  /**
   * useEffect
   */
  useEffect(() => {
    if(profile.id) {
        getProfile()
            .then((user) => {
                if(user.contact) setSoldeCredits(user.contact.tier.credits);
            });
    }

    setSelectedCard(null);
  }, [carto.selectedParcelle]);

  useEffect(() => {
    let selectedFeatures = [];
    if(carto.showedFeatures) {
        //Pour chaque menuItems, on compte le nombre de features sélectionnées
        menuItems.forEach((item) => {
            if(item.id == 'zonesUrbaine' || item.id == 'informations' || item.id == 'servitudes') {
                let count = 0;
                if(carto[item.id]) {
                    count = carto.showedFeatures.features.filter((feature) => {
                        return carto[item.id].features.map((feature) => feature.properties.id).includes(feature.properties.id);
                    }).length;
                }
                selectedFeatures[item.id] = count;
            }
        });
    }
    
    setSelectedFeaturesCount(selectedFeatures);
  }, [carto.showedFeatures]);

  /**
   * Handlers
   */
  const handleSelectItem = (item) => {
    if(item == carto.selectedCard) {
        setSelectedCard(null);
        if (onSelectItem) {
            onSelectItem(null);
        }
    }
    else {
        //Si l'utilisateur n'a pas le droit de voir les informations, on affiche la modal
        if(!carto.canViewParcelleData) {
            setShowTransactionModal(true);
        } else {
            setSelectedCard(item);
            if (onSelectItem) {
                onSelectItem(item);
            }
        }
    }
}

  return (
    <div 
        className={`sidebar bg-white ${isExpanded ? 'expanded' : ''}`} 
        onMouseEnter={() => setIsExpanded(true)} 
        onMouseLeave={() => setIsExpanded(fixExpanded?true:false)}
        style={{
            height: '100vh',
        }}>
        <div className="p-3">
            <Nav className="flex-column">
                <Container className="d-flex justify-content-between mb-3">
                    {!isExpanded ? (
                        <img src={logoMini} alt="logo" height="25" />
                    ) : (
                        <>
                            <img src={logo} alt="logo" height="25" />
                            <div>
                                <Button
                                    variant="link"
                                    onClick={() => setFixExpanded(!fixExpanded)}
                                >
                                    <FontAwesomeIcon icon={fixExpanded ? faCircle : faCircleDot} />
                                </Button>
                            </div>
                        </>
                    )}
                </Container>
                <Container className="mb-3" style={{ minHeight: '25px' }}>
                    {isExpanded && profile.id && (
                        <Badge bg="warning">
                            Vos crédits : {profile.contact?profile.contact.tier.credits:0}
                        </Badge>
                    )}
                </Container>
                {!isLibre && (
                    <>
                        <Nav.Item key="dossier">
                            <div
                                className={'nav-link '+(carto.selectedCard == 'dossier' ? 'active' : '')}
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleSelectItem('dossier')}
                            >
                                <FontAwesomeIcon icon={faFolder} style={{ minWidth: '20px' }} />
                                <span className="menu-label">
                                    Dossier
                                </span>
                            </div>
                        </Nav.Item>
                        <Nav.Item key="separator">
                            <div
                                className={'nav-link '}
                            >
                                <FontAwesomeIcon icon={faEllipsis} style={{ minWidth: '20px' }} />
                            </div>
                        </Nav.Item>
                    </>
                )}
                {menuItems.map((item) => {
                    return (
                    <Nav.Item key={item.id} className='mb-2'>
                        <div
                            className={'d-flex nav-link '+(carto.selectedCard == item.id ? 'active' : '')+' '+(carto.selectedParcelle ? '' : 'disabled')}
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleSelectItem(item.id)}
                        >
                            <div className='position-relative'>
                                <FontAwesomeIcon icon={item.icon} style={{ minWidth: '20px' }} />
                                {carto.selectedParcelle && (
                                    <>
                                        {carto['loading'+(String(item.id[0]).toUpperCase() + String(item.id).slice(1))] ? (
                                            <span className="position-absolute top-0 start-100 translate-middle" style={{ fontSize: '0.6rem' }}>
                                                <Spinner animation="border" size="sm" variant="warning" />
                                            </span>
                                        ) : (
                                            <span className={'position-absolute top-0 start-100 translate-middle badge rounded-pill '+(selectedFeaturesCount[item.id]?'bg-success':'bg-warning')} style={{ fontSize: '0.6rem' }}>
                                                {item.id == 'mutations' ? (
                                                    <>
                                                        {carto.mutations && carto.mutations.features.filter((feature) => {
                                                            return feature.properties.cadastre.code == carto.selectedParcelle.properties.code;
                                                        }).length}
                                                    </>
                                                ) : (
                                                    <>
                                                        {selectedFeaturesCount[item.id] ? (
                                                            <>
                                                                {selectedFeaturesCount[item.id]}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {carto['nb'+(String(item.id[0]).toUpperCase() + String(item.id).slice(1))] && (
                                                                    <>
                                                                        {carto['nb'+(String(item.id[0]).toUpperCase() + String(item.id).slice(1))]}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                            <span className="menu-label">
                                {item.label}
                            </span>
                        </div>
                    </Nav.Item>
                    );
                })}
            </Nav>
        </div>
        <TransactionModal open={showTransactionModal} setOpen={setShowTransactionModal} cadastre={carto.selectedParcelle} nbCredits={15} />
    </div>
  );
}